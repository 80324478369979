<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card v-if="deliveryRegion.id">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Изменить стоимость доставки для региона</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="editDeliveryRegionForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Регион</label>
                <md-select v-model="deliveryRegion.region" @focus="reset">
                  <md-option
                    v-for="item in regions"
                    :key="item.id"
                    :value="item.name"
                    placeholder="Регион"
                  >
                    {{ item.name }}
                  </md-option>
                </md-select>
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Стоимость</label>
                <md-input
                  v-model="deliveryRegion.cost"
                  type="number"
                  min="0"
                  @focus="reset"
                  @change="validateCost"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button
                class="md-raised md-success"
                @click="editDeliveryRegion"
              >
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      deliveryRegion: {},
    };
  },

  computed: {
    ...mapState({
      initDeliveryRegion: (state) => state.deliveryCost.deliveryRegion,
    }),

    ...mapGetters({ regions: "regions/sortedByName" }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    let success = await this.getDeliveryRegion(+this.$route.params.id);
    if (success) {
      this.$set(this.deliveryRegion, "id", this.initDeliveryRegion.id);
      this.$set(this.deliveryRegion, "region", this.initDeliveryRegion.region);
      this.$set(this.deliveryRegion, "cost", this.initDeliveryRegion.cost);
    }
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("deliveryCost", [
      "getDeliveryRegion",
      "updateDeliveryRegion",
    ]),

    async editDeliveryRegion() {
      const isValid = await this.$refs.editDeliveryRegionForm.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.updateDeliveryRegion({
        id: this.initDeliveryRegion.id,
        deliveryRegion: this.deliveryRegion,
      });

      if (success) {
        this.$router.push("/delivery-cost");
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    validateCost(event) {
      if (event.target.value < 0) {
        this.deliveryRegion.cost = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>